import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import RonakHero from '../components/RonakPipaliya/RonakHero';

import allApiData from "../api";
import PortfolioCom from '../components/Home/PortfolioCom';
import allData from '../allData';
import IndustriesCom from '../components/Home/IndustriesCom';

const hardikExpertisesData = allData.vimalExpertisesData;

const userData = {
    name: "Hardik Lalpurwala",
    designation: "Project Manager",
    image: '../assets/img/ronak-pipaliya/hardik-lalpurwala.png',
    tag: `hardik-lalpurwala`,
    phones: ['(+91) 8238288777'],
    // emails: ['vimal@vasundhara.io', 'vimal.vasundhara@gmail.com'],
    emails: ['hardik@vasundhara.io'],
    linkedin: 'https://www.linkedin.com/in/hardiklalpurwala-989982155',
    skype: 'https://join.skype.com/invite/ThIk9HM34qQg',
    behance: `https://www.behance.net/vasundharainfotech`,
    whatsapp: `https://wa.me/+918238288777`,
    description: [
        `Mr. Hardik Lalpurwala, an accomplished Project Manager at Vasundhara Infotech, combines his leadership, technical proficiency, and communication skills to drive excellence. `,
        `He executes and leads strong multi-functional teams, skillfully steering them towards achieving customer objectives, consistently delivering successful projects.`,
        `His unwavering commitment to quality and teamwork ensures the successful realization of client objectives within specified deadlines.`,
        `He is known for his exceptional leadership skills that have significantly contributed to the company's growth.`,
        `He possesses a profound understanding of business, consistently making a positive impact on company projects through strategic insights.`,
        `He’s committed to keeping his team informed about project progress and fostering a culture of continuous learning, thus empowering them to embrace new challenges.`,
        `He is adept at maintaining transparent and frequent communication with clients and team members at every project stage, ensuring timely project completion.`,
    ]
}

function HardikLalpurwala() {
    const tabNameData = ['Art & Animation'];
    useEffect(() => {
        allApiData.aosinit();
    }, []);


    return (
        <Layout footernone={false} padding={true} popup={true}>
            <div className='main-ronak'>
                <div className='ronak-hero'>
                    <RonakHero userData={userData} />
                </div>

                <div className="ronak-portfolio">
                    <PortfolioCom
                        heading="Wall of Pride"
                        isTabOn={true}
                        isImageComp={true}
                        isViewBtn={true}
                        // viewurl={`portfolio?type=website_development`}
                        viewurl={`portfolio`}
                        viewButton={'View the entire portfolio'}
                        tabData={tabNameData}
                        typeWise='app_type'
                        isPagination={false}
                        activeTab='Character Design'
                        //portfolios={portFolio}
                    />
                </div>

                <div className="ronak-expertises">
                    <IndustriesCom h1="Expertises" industriesData={hardikExpertisesData} buttonOn={false} bg={true} upperCase={true} />
                </div>
            </div>
        </Layout>
    )
}

export default HardikLalpurwala